<script>
import LinkBox from '../components/LinkBox.vue';

export default {
  components: {
    LinkBox,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    title() {
      return this.block.fields.Title;
    },
    heading() {
      return this.block.fields.HeadingLevel?.value || 'h4';
    },
    readMore() {
      // eslint-disable-next-line camelcase
      return this.block.fields.CompositeLink_ReadMore?.url;
    },
    links() {
      // eslint-disable-next-line camelcase
      return this.block.fields.CompositeLink_Links;
    },
  },
};
</script>

<template>
  <div class="link-boxes__container">
    <div class="link-boxes__title-container">
      <component :is="heading" v-if="title"
        class="link-boxes__title"
      >
        {{title}}
      </component>
      <router-link
        v-if="readMore"
        :to="readMore"
        class="link-boxes__read-more"
      >
        ({{ $globalTexts.global__label_products_menu_see_all }})
      </router-link>
    </div>
    <div class="link-boxes__box-container">
      <link-box
        v-for="(link, idx) in links"
        :key="idx"
        :link="link"
      />
    </div>
  </div>
</template>

<style>
  .link-boxes__container {
    max-width: calc(var(--header-max-width) + 20px);
    margin: auto;
    padding: 20px 10px;
    overflow: hidden;
  }

  .link-boxes__title {
    text-align: left;
    color: var(--color-black);
    display: inline;
  }

  .link-boxes__read-more {
    font-weight: 700;
    font-size: 16px;
    margin-left: 10px;
    display: inline-block;
  }

  .link-boxes__title-container {
    margin-bottom: 20px;
  }

  @media (--tabletAndDesktop) {
    .link-boxes__box-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      grid-template-rows: auto;
      gap: 40px 30px;
    }
  }
</style>
